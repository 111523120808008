import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter, Link, Routes, Route } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import store from "./reducer-toolkit";
import Signup from "./views/Users/Signup";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
        <div>
          <Link to="/signup">Sign Up</Link>
          <Link to="/">Home</Link>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
