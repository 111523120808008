import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { User } from '../../types/User';
import { UserApi } from '../../reducer-toolkit/user';
import HFCField, { InputTypes } from '../CommonWidgets/HFCField';

export default function Signup() {
    const initialValues: Partial<User> = {
        name: '',
        email: '',
        DOB: undefined,
    };
    const [createUserTrigger, mutationState] = UserApi.useCreateUserMutation();

    function handleCredentialResponse(response: { credential: string }) {
        console.log('Encoded JWT ID token: ' + response.credential);
    }
    useEffect(() => {
        const w = window as Window & typeof globalThis & { google: any };
        w?.google.accounts.id.initialize({
            client_id:
                '257419185134-r4q18agaujiiptsmkviot251uch3kgll.apps.googleusercontent.com',
            callback: handleCredentialResponse,
        });
        w?.google.accounts.id.renderButton(
            document.getElementById('buttonDiv'),
            { theme: 'outline', size: 'large' }, // customization attributes
        );
        w?.google.accounts.id.prompt(); // also display the One Tap dialog
    }, []);
    return (
        <>
            <div id="buttonDiv"></div>

            {/* <div
                id="g_id_onload"
                data-client_id="257419185134-r4q18agaujiiptsmkviot251uch3kgll.apps.googleusercontent.com"
                data-login_uri="http://localhost:3001/dashboard"
                data-auto_prompt="true"
            ></div>
            <div
                className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left"
            ></div> */}
        </>
    );
    // return (
    //     <div className="flex">
    //         <Formik
    //             initialValues={initialValues}
    //             onSubmit={async (data) => {
    //                 console.log(`Data ${JSON.stringify(data)}`);
    //                 return await createUserTrigger(data);
    //             }}
    //         >
    //             {(props) =>
    //                 mutationState.isLoading ? (
    //                     <div>Spinner here</div>
    //                 ) : (
    //                     <form
    //                         className="flex-auto p-6"
    //                         onSubmit={props.handleSubmit}
    //                     >
    //                         <HFCField
    //                             type={InputTypes.INPUT}
    //                             name="name"
    //                             label="name"
    //                         />
    //                         <HFCField
    //                             type={InputTypes.INPUT}
    //                             name="email"
    //                             label="email"
    //                         />
    //                         <HFCField
    //                             type={InputTypes.INPUT}
    //                             name="DOB"
    //                             label="DOB"
    //                         />
    //                         <button type="submit">Sign up</button>
    //                     </form>
    //                 )
    //             }
    //         </Formik>
    //     </div>
    // );
}
