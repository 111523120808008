import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../types/User";

export const UserApi = createApi({
  reducerPath: "UserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/user",
  }),
  endpoints: (builder) => ({
    // TODO: define user session data
    getUser: builder.query<User, { email: string }>({
      query: ({ email }) => `?user_email=${email}`,
    }),
    //
    createUser: builder.mutation<User, Partial<User>>({
      query: (user) => {
        console.log(`User ${JSON.stringify(user)}`);
        return {
          url: `create`,
          method: "POST",
          body: user,
        };
      },
      transformResponse: (response: { data: User }) => response.data,
    }),
  }),
});

export default UserApi;
