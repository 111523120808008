import { configureStore } from "@reduxjs/toolkit";
import eventsApi from "./events";
import userApi from "./user";
const store = configureStore({
  reducer: {
    [eventsApi.reducerPath]: eventsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([eventsApi.middleware, userApi.middleware]),
});

export default store;
