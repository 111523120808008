import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HFCEvent } from "../types/Event";

export const eventsApi = createApi({
  reducerPath: "eventsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "events",
  }),
  endpoints: (builder) => ({
    getEventsByTimeDurations: builder.query<
      HFCEvent,
      { timeframeStart: Number; timeframeEnd: Number }
    >({
      query: ({ timeframeStart, timeframeEnd }) =>
        `?start=${timeframeStart}&end=${timeframeEnd}`,
    }),
  }),
});

export default eventsApi;
